
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import End from './EndMaintenance.vue'
import True from './TrueMaintenance.vue'
// import Close from './CloseRepair.vue'
import { FileInfo } from '@/types/common'

@Component({
  components: { End, True },
  filters: {
    flowName (val: any) {
      if (val === '2') {
        return '开始保养'
      } else if (val === '3') {
        return '结束保养'
      } else if (val === '4') {
        return '负责人确认'
      } else if (val === '5') {
        return '已关闭'
      }
    },
    imgListFormat (list: FileInfo[]) {
      const imgTypeList = ['jpeg', 'jpg', 'png']
      const imgList: Array<string> = []
      list.forEach((item: FileInfo) => {
        const str = item.fileName.split('.')
        if (imgTypeList.find((items) => items === str[str.length - 1].toLowerCase())) {
          imgList.push(item.filePrefix + item.fileUrl)
        }
      })
      return imgList || []
    }
  }
})

export default class extends Vue {
  @Prop() readonly detailShow!: boolean
  @Prop() readonly maintainId!: string
  @Prop() readonly auth!: boolean
  @Prop() readonly goWhere!: string
  // 记录类型1报修2开始保养/保养3结束保养/保养4确认5转办6关闭
  private locStyle = {
    2: 'step_start',
    3: 'step_end',
    4: 'step_true',
    5: 'step_close'
  }

  private locStyle2 = {
    2: 'line_start',
    3: 'line_end',
    4: 'line_true',
    5: 'line_close'
  }

  private formData = {
    flowList: [],
    termList: [],
    planTermList: []
  }

  private imgList = ['jpeg', 'jpg', 'png']
  private videoList = ['mp4', 'mov']

  private activeName = 'first'
  protected loading = false
  // protected userId = false // 判断当前用户是否有操作按钮权限
  private endShow = false // 结束
  private trueShow = false // 确认
  private maintenanceHeadName: any = '' // 确认负责人姓名
  private termList = [] // 实际保养项列表
  private planTermList = [] // 计划保养项列表

  get userId () {
    return this.$store.state.userInfo.userId
  }

  @Watch('detailShow', { immediate: true })
  isDetail (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    this.loading = true
    // console.log('this.goWhere', this.goWhere)
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryMaintenanceByMaintainId, { maintainId: this.maintainId }).then(res => {
      this.formData = res || {}
      // console.log('goWhere', this.goWhere)
      if (this.goWhere === 'end') {
        this.$nextTick(() => {
          this.endShow = true
        })
      } else if (this.goWhere === 'true') {
        this.$nextTick(() => {
          this.trueShow = true
        })
      }

      // 拿到确认负责人的姓名(后端不能直接返回，我们需要做处理)
      let trueFlow: any = {
        userName: ''
      }
      trueFlow = this.formData?.flowList.find((item: any) => item.flowType === '4')
      if (trueFlow) {
        this.maintenanceHeadName = trueFlow.userName
      }
      // 回显实际保养项
      this.termList = this.formData.termList.filter((item: any) => item.termFlag === '1').map((item: any) => item.termName) as any
      // 回显计划保养项
      this.planTermList = this.formData.planTermList.filter((item: any) => item.termFlag === '1').map((item: any) => item.termName) as any
    }).finally(() => {
      this.loading = false
    })
  }

  onEnd () {
    this.endShow = true
  }

  onTrue () {
    this.trueShow = true
  }

  onDelete () {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.machineryManage.deleteYhMachineryMaintenance, { maintainId: this.maintainId }).then(() => {
        this.handleClose()
        this.$message.success('删除成功')
      })
    })
  }

  onSuccess () {
    // console.log('成功')
    this.$emit('update:goWhere', '')
    this.getDetail()
  }

  handleClose () {
    // console.log('关闭详情弹窗')
    this.$emit('update:detailShow', false)
    this.$emit('update:goWhere', '')
    this.formData =
     {
       flowList: [],
       termList: [],
       planTermList: []
     }
    this.activeName = 'first'
    this.maintenanceHeadName = ''
    this.termList = []
    this.planTermList = []
    this.$emit('success')
  }
}
