
import { ElForm } from 'element-ui/types/form'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  components: { },
  filters: {
    strategyNum (val: any) {
      if (val.strategyType === '1') {
        return `${val.strategyNum} 天/次`
      } else if (val.strategyType === '2') {
        return `${val.strategyNum} 小时/次`
      }
    }
  }
})

export default class extends Vue {
  @Prop() readonly addShow!: boolean

  @Watch('addShow')
  isAdd (val: string) {
    if (val) {
      this.formData.startDate = `${this.$dayjs().format('YYYY-MM-DD HH:mm:ss')}`
    }
  }

  @Watch('formData.startDate', { immediate: false })
  watchStartDate (day: string, oldDay: string) {
    // 如果年月日没有变化,不改变时间和时间限制
    // console.log('WatchBackTime', day, oldDay)
    if (day.substring(0, 10) === oldDay.substring(0, 10)) {
      // console.log('年月日没有变化')
      return
    }

    const nowTime: any = this.$dayjs().format('YYYY-MM-DD')
    const selectTime: any = day?.substring(0, 10)
    if (nowTime === selectTime) {
      // console.log('当天')
      this.pickerOptions.selectableRange = `'00:00:00 - ${this.$dayjs().format('HH:mm:ss')}`
      this.formData.startDate = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
    } else {
      // console.log('非当天')
      this.pickerOptions.selectableRange = '00:00:00 - 23:59:59'
      // this.formData.startDate = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
      this.formData.startDate = `${selectTime} 23:59:59}`
    }
  }

  private pickerOptions = {
    // 时间不能大于当前时间 且 不能小于当前时间30天
    disabledDate: (time: any) => {
      let sevenAfter = 30 * 24 * 3600 * 1000
      sevenAfter = Date.now() - sevenAfter
      return time.getTime() > Date.now() || time.getTime() < sevenAfter
    },
    selectableRange: `'00:00:00 - ${this.$dayjs().format('HH:mm:ss')}`
  }

  private rules = {
    machineryType: [
      { required: true, message: '请选择机械类型', trigger: ['blur', 'change'] }
    ],
    machineryId: [
      { required: true, message: '请选择机械名称', trigger: ['blur', 'change'] }
    ],
    maintenanceUserId: [
      { required: true, message: '请选择保养人', trigger: ['blur', 'change'] }
    ],
    startDate: [
      { required: true, message: '请选择保养时间', trigger: ['blur', 'change'] }
    ]
  }

  private userNameList = []

  private formData = {
    // 提交表单字段
    machineryId: '', // 机械ID
    maintenanceUserId: '', // 保养人id
    planTermList: [], // 计划保养项
    startDate: '', // 开始日期

    // 展示字段，不提交表单
    machineryType: '', // 机械类型
    machineryCode: '', // 机械编号
    machineryBrand: '', // 机械品牌
    machineryModel: '', // 机械型号
    maintenanceId: '系统自动生成'
  }

  private machineryTypeList = [] // 机械分类列表
  private machineryNameList = [] //  机械名称
  private checkMaintenanceList = [] //  选中的保养项

  created () {
    // machineryId存在 表名是从归还报修过来的
    if (this.$route.query.machineryId) {
      // console.log('有id了')
      this.initData()
    // 否则就是正常的新增报修
    } else {
      this.getMachineryTypeList()
    }
    // this.getUserList()
  }

  // getUserList () {
  //   this.$axios.get(this.$apis.personnel.personnelPage, {
  //     page: '',
  //     size: ''
  //   }).then((res) => {
  //     // console.log('人员列表', res)
  //     this.userNameList = res.list
  //   })
  // }

  // 项目人员列表
  getUserList (id: string) {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: id
    }).then(res => {
      // console.log('用户列表', res)
      this.userNameList = res || []
    })
  }

  initData () {
    const routeQuery = this.$route.query
    // this.formData.maintenanceUserId = this.userInfo.userId
    this.formData.startDate = `${this.$dayjs().format('YYYY-MM-DD HH:mm:ss')}`

    // 先渲染机械分类列表
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'machineryType' }).then(res => {
      this.machineryTypeList = res.machineryType || []
      this.formData.machineryType = routeQuery.machineryType as string

      // 再根据分类渲染机械名称列表
      this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByPage, { machineryType: routeQuery.machineryType }).then(res => {
        // console.log('列表', res.list)
        this.machineryNameList = res.list || {}
        // 给 计划保养项、machineryId、编号、品牌、型号、管家、领用人赋值
        this.formData.machineryId = routeQuery.machineryId as any
        this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByMachineryId, { machineryId: routeQuery.machineryId }).then(res => {
          if (res) {
            // console.log('机械详情', res)
            this.getUserList(res.projectId)
            this.formData.planTermList = res.termList
            this.formData.machineryCode = res.machineryCode
            this.formData.machineryBrand = res.machineryBrand
            this.formData.machineryModel = res.machineryModel
          }
        })
      })
    })
  }

  // 机械分类
  getMachineryTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'machineryType' }).then(res => {
      // console.log('分类', res)
      this.machineryTypeList = res.machineryType || []
    })
  }

  // 选择机械分类
  selectMachineryType (val: any) {
    // console.log('选择机械类型', val)
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByPage, { machineryType: val }).then(res => {
      // 重置机械名称列表
      this.machineryNameList = res.list || {}
      this.formData.machineryId = ''
      // 清空保养项、编号、品牌、型号
      this.formData.planTermList = []
      this.formData.machineryCode = ''
      this.formData.machineryBrand = ''
      this.formData.machineryModel = ''
    })
  }

  // 选择机械名称
  selectMachineryName (val: any) {
    // 给计划保养项、编号、品牌、型号
    this.$axios.get(this.$apis.machineryManage.selectYhMachineryInfoByMachineryId, { machineryId: val }).then(res => {
      if (res) {
        console.log('res', res)
        if (res.machineryState === '2') {
          this.$message.warning('当前机械正在使用中，无法新增保养')
          this.formData.machineryId = ''
          return
        } else if (res.machineryState === '3') {
          this.$message.warning('当前机械处在待维修状态，无法新增保养')
          this.formData.machineryId = ''
          return
        } else if (res.machineryState === '4') {
          this.$message.warning('当前机械正在维保中，无法新增保养')
          this.formData.machineryId = ''
          return
        } else if (res.machineryState === '5') {
          this.$message.warning('当前机械已终止使用，无法新增保养')
          this.formData.machineryId = ''
          return
        }
        this.getUserList(res.projectId) // 重置保养人列表
        this.formData.maintenanceUserId = '' // 重置保养人
        this.formData.planTermList = res.termList
        this.formData.machineryCode = res.machineryCode
        this.formData.machineryBrand = res.machineryBrand
        this.formData.machineryModel = res.machineryModel
      }
    })
  }

  selectTable (row: any) {
    // console.log('row2', row)
    // const arr: any = row
    this.checkMaintenanceList = row
  }

  onSubmit () {
    (this.$refs.formData as ElForm).validate(valid => {
      if (valid) {
        // console.log('提交数据', this.formData)
        if (!this.checkMaintenanceList.length) {
          this.$message.warning('请选择保养计划')
          return
        }
        const planTermList = this.checkMaintenanceList.map((item: any) => {
          return {
            termId: item.termId
          }
        }) as any
        const params = {
          machineryId: this.formData.machineryId, // 机械ID
          maintenanceUserId: this.formData.maintenanceUserId, // 保养人id
          planTermList: planTermList, // 计划保养项
          startDate: this.formData.startDate // 开始日期
        }
        // console.log('log', params)
        this.$axios.post(this.$apis.machineryManage.insertYhMachineryMaintenance, params).then(() => {
          this.$message.success('新增成功')
          // this.$emit('closeDialog')
          this.handleClose()
          this.$emit('success')
          this.$router.push({ name: 'upkeep' })
        })
      }
    })
  }

  handleClose () {
    // console.log('关闭弹窗')
    this.clearData();
    (this.$refs.formData as ElForm).resetFields()
    this.$emit('update:addShow', false)
  }

  // 清空数据
  clearData () {
    this.machineryNameList = [] //  机械名称
    this.checkMaintenanceList = [] // 选中保养项
    this.formData = {
      // 提交表单字段
      machineryId: '', // 机械ID
      maintenanceUserId: '', // 保养人id
      planTermList: [], // 计划保养项
      startDate: '', // 开始日期

      // 展示字段，不提交表单
      machineryType: '', // 机械类型
      machineryCode: '', // 机械编号
      machineryBrand: '', // 机械品牌
      machineryModel: '', // 机械型号
      maintenanceId: '系统自动生成'
    }
  }
}
